.ProseMirror {
  width: 100%;
}
.ProseMirror h4 + * {
  margin-top: theme("spacing.4");
}
.ProseMirror * + h4 {
  margin-top: theme("spacing.8");
}
.ProseMirror h4 {
  font-size: theme("fontSize.2xl");
  line-height: theme("lineHeight.snug");
  font-weight: theme("fontWeight.light");
  text-transform: uppercase;
}
.ProseMirror a {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.ProseMirror p + p {
  margin-top: theme("spacing.4");
}
@media (max-width: theme("screens.lg")) {
  .ProseMirror p {
    column-count: 1 !important;
  }
}

.ProseMirror ul {
  list-style-type: disc;
  list-style-position: inside;
}
.ProseMirror * + ul {
  margin-top: theme("spacing.2");
}

.ProseMirror ol {
  list-style-type: decimal;
  list-style-position: inside;
}
.ProseMirror * + ol {
  margin-top: theme("spacing.2");
}

/* Table */
.ProseMirror table {
  width: 100%;
}
.ProseMirror table tr {
  border-top: theme("spacing.px") solid;
  border-bottom: theme("spacing.px") solid;
  border-color: theme("colors.black.DEFAULT");
}
.ProseMirror table td {
  padding: theme("spacing[2]") 0;
  padding-right: theme("spacing.4");
  vertical-align: top;
}
@media screen(md) {
  .ProseMirror table td {
    padding: theme("spacing[1.5]") 0;
    padding-right: theme("spacing.4");
  }
  .ProseMirror table td > * {
    @apply line-clamp-1;
  }
}
.ProseMirror table td a {
  text-decoration: none;
}
.ProseMirror table td a::after {
  content: "↗";
  margin-left: theme("spacing.1");
}
