/* If you need to add @import statements, do so up here */
@import "fonts.css";
@import "prose-mirror.css";

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body.light {
  color: theme("colors.black.DEFAULT");
  background: theme("colors.white.DEFAULT");
}
body.light_2 {
  color: theme("colors.black.DEFAULT");
  background: theme("colors.white.2");
}
body.dark {
  color: theme("colors.white.DEFAULT");
  background: theme("colors.black.DEFAULT");
}
body.dark_2 {
  color: theme("colors.white.DEFAULT");
  background: theme("colors.black.2");
}

body.light .background-placeholder {
  background: theme("colors.black.2");
}
body.light .background-placeholder {
  background: theme("colors.black.2");
}
body.dark .background-placeholder {
  background: theme("colors.white.2");
}
body.dark .background-placeholder {
  background: theme("colors.white.2");
}

.hidden-details > summary {
  list-style: none;
}
.hidden-details > summary::-webkit-details-marker {
  display: none;
}

/* .mapboxgl-ctrl-logo {
    display: none !important;
} */

/* Mobile Header */
.mobile-header[open] .hidable {
  opacity: 0;
}

.mobile-header .navbar {
  opacity: 0;
  transform: translateY(-100%);
}
.mobile-header[open] .navbar {
  opacity: 0.95;
  transform: translateY(0);
}
