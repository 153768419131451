@font-face {
  font-family: "Everett";
  src: url("../fonts/TWKEverett-Light-web.ttf") format("ttf"),
       url("../fonts/TWKEverett-Light-web.woff") format("woff"),
       url("../fonts/TWKEverett-Light-web.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Everett";
  src: url("../fonts/TWKEverett-LightItalic-web.ttf") format("ttf"),
       url("../fonts/TWKEverett-LightItalic-web.woff") format("woff"),
       url("../fonts/TWKEverett-LightItalic-web.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Everett";
  src: url("../fonts/TWKEverett-Regular-web.ttf") format("ttf"),
       url("../fonts/TWKEverett-Regular-web.woff") format("woff"),
       url("../fonts/TWKEverett-Regular-web.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Everett";
  src: url("../fonts/TWKEverett-RegularItalic-web.ttf") format("ttf"),
       url("../fonts/TWKEverett-RegularItalic-web.woff") format("woff"),
       url("../fonts/TWKEverett-RegularItalic-web.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Everett";
  src: url("../fonts/TWKEverett-Bold-web.ttf") format("ttf"),
       url("../fonts/TWKEverett-Bold-web.woff") format("woff"),
       url("../fonts/TWKEverett-Bold-web.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Everett";
  src: url("../fonts/TWKEverett-BoldItalic-web.ttf") format("ttf"),
       url("../fonts/TWKEverett-BoldItalic-web.woff") format("woff"),
       url("../fonts/TWKEverett-BoldItalic-web.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
